<template>
    <div>
        <AdminTop
            :heading="schoolClassId ? 'Redigera klass' : 'Skapa klass'"
        />

        <BaseForm
            v-if="schoolClass"
            :loading="loading"
            :submit-label="schoolClassId ? 'Uppdatera' : 'Lägg till'"
            @submit="handleSubmit"
        >
            <FormGroup
                label="Namn"
                name="name"
            >
                <InputField
                    v-model="schoolClass.name"
                    name="name"
                    type="text"
                    required
                />
            </FormGroup>
        </BaseForm>
    </div>
</template>

<script>
import AdminTop from '@/components/ui/AdminTop';
import BaseForm from '@/components/form/BaseForm';
import FormGroup from '@/components/form/FormGroup';
import InputField from '@/components/form/InputField';
import SchoolClassService from '@/services/admin/SchoolClassService';

export default {
    components: {
        AdminTop,
        BaseForm,
        FormGroup,
        InputField
    },

    data: () => ({
        loading: false,
        schoolClass: null
    }),

    computed: {
        schoolClassId () {
            return this.$route.params.id;
        }
    },

    async created () {
        this.schoolClass = await this.getClassroom();
    },

    methods: {
        async getClassroom () {
            if (!this.schoolClassId) {
                return {
                    name: null
                };
            }

            const res = await SchoolClassService.get(this.$route.params.id);

            return res.data;
        },

        async handleSubmit () {
            this.loading = true;

            try {
                if (this.schoolClassId) {
                    await this.update();
                } else {
                    await this.create();
                }
            } catch (error) {
                // eslint-disable-next-line
                console.log(error);
            }

            this.loading = false;
        },

        async create () {
            const res = await SchoolClassService.create(this.schoolClass);

            if (res.status === 200 && res?.data?.schoolClass?.id) {
                this.$router.push(`/admin/events/schoolClasss/${res.data.schoolClass.id}`);
            }
        },

        async update () {
            return await SchoolClassService.update(this.schoolClassId, this.schoolClass);
        }
    }
};
</script>
